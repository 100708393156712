import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Icon } from "@ryerson/frontend.assets";
import { Link } from "@ryerson/frontend.navigation";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Moment from "react-moment";
import ContactUsForm from "@components/Shared/ContactUsRefactor/ContactUs";
import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";
import { PodcastMin } from "./PodcastDetail";

export type PodcastStaticContent = {
	title: string;
	blurb: string;
	backgroundImage: string;
	imageUrl: string;
	followUs: string;
	apple: string;
	spotify: string;
	amazon: string;
	youtube: string;
	episodes: string;
	viewEpisode: string;
	loadMore: string;
};

const BreadcrumbContainer = styled.div`
	padding-top: 52px;
	position: relative;
	z-index: 1;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-top: 28px;
			}
		`;
	}}
`;
const LoadMore = styled.div`
	display: block;
	margin: 0 auto;
	cursor: pointer;
	width: 210px;
	box-sizing: border-box;
	line-height: 36px;
	text-align: center;
	height: 40px;
	border-radius: 24px;
`;

const HeroContainer = styled.div`
	display: block;
	width: 100%;
	padding-top: 85px;
	box-sizing: border-box;
	height: auto;
	margin-bottom: 50px;
	position: relative;
`;

const HeroImage = styled.div`
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const PoweredBy = styled.div`
	display: block;
	width: 260px;
	height: 285px;
	position: absolute;
	top: 10px;
	right: 0px;
	img {
		width: 100%;
		height: auto;
	}
`;

const stayTunedParent = "Podcasts-Landing";

const Landing: React.FC<LanguageContent<PodcastStaticContent>> = (staticContent) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();

	const queryData = useStaticQuery(graphql`
		query AllPodcastsQuery {
			allContentfulPodcast {
				nodes {
					title
					videoThumbnail {
						file {
							url
						}
					}
					slug
					publishDate
					node_locale
				}
			}
		}
	`);

	let podcastContent: LanguageContent<PodcastMin[]> = {
		en: queryData.allContentfulPodcast.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "en")
			.sort(
				(a: any, b: any) =>
					new Date(b.publishDate).valueOf() - new Date(a.publishDate).valueOf()
			),
		fr: queryData.allContentfulPodcast.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "fr")
			.sort(
				(a: any, b: any) =>
					new Date(b.publishDate).valueOf() - new Date(a.publishDate).valueOf()
			),
		es: queryData.allContentfulPodcast.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "es")
			.sort(
				(a: any, b: any) =>
					new Date(b.publishDate).valueOf() - new Date(a.publishDate).valueOf()
			),
	};

	const [slicePoint, setSlicePoint] = React.useState<number>(9);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="205px" type="tertiary">
					<HeroImage
						css={css`
							background-image: url(${staticContent[language].backgroundImage});
							background-position: center center;
							background-size: cover;
							background-repeat: no-repeat;
							height: 410px;
							top: 0px;
							left: 0px;
						`}
					/>
					<div
						css={css`
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 410px;
							background: linear-gradient(
								90deg,
								rgba(0, 40, 85, 0.4) 18.5%,
								rgba(0, 104, 161, 0.731469) 51%,
								#009cde 77%
							);
							z-index: 1;
						`}
					>
						<div
							css={css`
								display: block;
								width: 100%;
								max-width: 1160px;
								margin: 0 auto;
								height: auto;
							`}
						>
							<BreadcrumbContainer
								theme={theme}
								css={css`
									padding-top: 36px;
								`}
							>
								<Breadcrumb type="tertiary" size="xs" />
							</BreadcrumbContainer>
							<HeroContainer
								css={css`
									margin-bottom: 30px;
									padding-top: 65px;
								`}
							>
								<PoweredBy>
									<img src={staticContent[language].imageUrl} />
								</PoweredBy>
								<Typography
									variant="h1"
									type="tertiary"
									css={css`
										margin-bottom: 20px;
										max-width: calc(100% - 240px);
										letter-spacing: -2px;
										@media (max-width: ${theme.breakpoints.xl}) {
											font-size: 56px;
										}
									`}
									weight="bolder"
								>
									{staticContent[language].title}
								</Typography>
								<Typography
									variant="div"
									css={css`
										margin: 0px;
										max-width: 760px;
										white-space: pre-line;
									`}
									color={theme.colors.tertiary.header}
									size="sm"
								>
									{staticContent[language].blurb}
								</Typography>
							</HeroContainer>
							<Typography
								variant="div"
								size="sm"
								type="tertiary"
								color={theme.colors.primary.white}
							>
								{staticContent[language].followUs}
							</Typography>
							<div
								css={css`
									width: 100%;
									display: block;
									margin-top: 10px;
									height: auto;
								`}
							>
								<Link
									to={staticContent[language].apple}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/ApplePodcast.png"
										alt="Apple Podcast"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={staticContent[language].spotify}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/SpotifyPodcast.png"
										alt="Spotify"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={staticContent[language].amazon}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/AmazonPodcast.png"
										alt="Amazon Music"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={staticContent[language].youtube}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/YoutubePodcast.png"
										alt="YouTube"
										css={css`
											display: inline-block;
											vertical-align: middle;
										`}
									/>
								</Link>
							</div>
						</div>
					</div>
				</ContentSection>
				<ContentSection type="primary" vPadding="40px">
					<Typography
						variant="h3"
						type="primary"
						css={css`
							margin-bottom: 20px;
						`}
					>
						{staticContent[language].episodes}
					</Typography>
					<Flex
						direction="row"
						alignItems="center"
						justifyContent="flex-start"
						wrap="wrap"
						css={css`
							gap: 40px;
						`}
					>
						{podcastContent[language]
							.slice(0, slicePoint)
							.map((podcast: any, index: number) => {
								return (
									<FlexItem
										css={css`
											width: 360px;
											height: 294px;
											background-color: ${theme.colors.secondary.background};
											padding: 20px 50px;
											box-sizing: border-box;
										`}
									>
										<img
											src={podcast.videoThumbnail.file.url}
											alt={podcast.title}
											css={css`
												width: 100%;
												height: 142px;
												object-fit: cover;
												display: block;
												margin-bottom: 10px;
											`}
										/>
										<Typography
											variant="div"
											size="xs"
											type="secondary"
											css={css`
												margin-bottom: 10px;
											`}
										>
											Released on{" "}
											<Moment format="MMM D, YYYY">
												{podcast.publishDate}
											</Moment>
										</Typography>
										<Typography
											variant="h5"
											type="secondary"
											css={css`
												margin-bottom: 10px;
											`}
										>
											{podcast.title}
										</Typography>
										<Link
											to={`/metal-resources/metal-market-intelligence/podcast/${podcast.slug}`}
										>
											<div
												css={css`
													display: inline-block;
													vertical-align: middle;
													width: 24px;
													height: 24px;
													text-align: center;
													line-height: 26px;
													border-radius: 24px;
													background-color: ${theme.colors.primary
														.primaryBrand};
													margin-right: 10px;
												`}
											>
												<Icon
													icon="chevron-right"
													color={theme.colors.primary.white}
													size="xs"
												/>
											</div>
											<Typography
												variant="div"
												type="secondary"
												size="sm"
												weight="bold"
												color={theme.colors.primary.header}
												css={css`
													display: inline-block;
													vertical-align: middle;
												`}
											>
												{staticContent[language].viewEpisode}
											</Typography>
										</Link>
									</FlexItem>
								);
							})}
					</Flex>
					{slicePoint < podcastContent[language].length && (
						<LoadMore
							css={css`
								margin-top: 80px;
								border: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setSlicePoint(slicePoint + 3);
							}}
						>
							<Typography
								variant="span"
								color={theme.colors.primary.darkGray}
								weight="bold"
								size="sm"
							>
								{staticContent[language].loadMore}
							</Typography>
						</LoadMore>
					)}
				</ContentSection>
				<StayTunedComponent parent={stayTunedParent} parentCategory={""} />
				<ContactUsForm background="primary" withImage={false} />
				<ContentSection type="primary" vPadding="20px"></ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection vPadding="370px" type="tertiary">
					<HeroImage
						css={css`
							background-image: url(${staticContent[language].backgroundImage});
							background-position: center center;
							background-size: cover;
							background-repeat: no-repeat;
							height: 740px;
							top: 0px;
							left: 0px;
							display: block;
						`}
					/>
					<div
						css={css`
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 740px;
							z-index: 1;
							background: linear-gradient(
								180deg,
								rgba(0, 40, 85, 0.4) 42.06%,
								rgba(0, 104, 161, 0.731469) 53.55%,
								#009cde 77%
							);
						`}
					>
						<div
							css={css`
								display: block;
								width: 100%;
								margin: 0 auto;
								height: auto;
								padding-left: 20px;
								padding-right: 20px;
								box-sizing: border-box;
							`}
						>
							<BreadcrumbContainer
								theme={theme}
								css={css`
									padding-top: 28px;
								`}
							>
								<Breadcrumb type="tertiary" size="xs" />
							</BreadcrumbContainer>
							<HeroContainer
								css={css`
									margin-bottom: 30px;
									padding-top: 35px;
								`}
							>
								<Typography
									variant="h1"
									type="tertiary"
									css={css`
										margin-bottom: 20px;
										letter-spacing: -2px;
									`}
									weight="bolder"
								>
									{staticContent[language].title}
								</Typography>
								<Typography
									variant="div"
									css={css`
										margin: 0px;
										white-space: pre-line;
									`}
									color={theme.colors.tertiary.header}
									size="sm"
								>
									{staticContent[language].blurb}
								</Typography>
							</HeroContainer>
							<Typography
								variant="div"
								size="sm"
								type="tertiary"
								color={theme.colors.primary.white}
							>
								{staticContent[language].followUs}
							</Typography>
							<div
								css={css`
									width: 100%;
									display: block;
									margin-top: 10px;
									height: auto;
								`}
							>
								<Link
									to={staticContent[language].apple}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/ApplePodcast.png"
										alt="Apple Podcast"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={staticContent[language].spotify}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/SpotifyPodcast.png"
										alt="Spotify"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={staticContent[language].amazon}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/AmazonPodcast.png"
										alt="Amazon Music"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={staticContent[language].youtube}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/YoutubePodcast.png"
										alt="YouTube"
										css={css`
											display: inline-block;
											vertical-align: middle;
										`}
									/>
								</Link>
							</div>

							<img
								src={staticContent[language].imageUrl}
								css={css`
									display: block;
									width: 200px;
									height: auto;
									margin: 50px auto 0px auto;
								`}
							/>
						</div>
					</div>
				</ContentSection>
				<ContentSection type="primary" vPadding="30px">
					<Typography
						variant="h3"
						type="primary"
						css={css`
							margin-bottom: 20px;
						`}
					>
						{staticContent[language].episodes}
					</Typography>
					<Flex
						direction="row"
						alignItems="center"
						justifyContent="flex-start"
						wrap="wrap"
						css={css`
							gap: 16px;
						`}
					>
						{podcastContent[language]
							.slice(0, slicePoint)
							.map((podcast: any, index: number) => {
								return (
									<FlexItem
										css={css`
											width: 300px;
											height: 294px;
											background-color: ${theme.colors.secondary.background};
											padding: 20px;
											box-sizing: border-box;
										`}
									>
										<img
											src={podcast.videoThumbnail.file.url}
											alt={podcast.title}
											css={css`
												width: 100%;
												height: 142px;
												object-fit: cover;
												display: block;
												margin-bottom: 10px;
											`}
										/>
										<Typography
											variant="div"
											size="xs"
											type="secondary"
											css={css`
												margin-bottom: 10px;
											`}
										>
											Released on{" "}
											<Moment format="MMM D, YYYY">
												{podcast.publishDate}
											</Moment>
										</Typography>
										<Typography
											variant="h5"
											type="secondary"
											css={css`
												margin-bottom: 10px;
											`}
										>
											{podcast.title}
										</Typography>
										<Link
											to={`/metal-resources/metal-market-intelligence/podcast/${podcast.slug}`}
										>
											<div
												css={css`
													display: inline-block;
													vertical-align: middle;
													width: 24px;
													height: 24px;
													text-align: center;
													line-height: 26px;
													border-radius: 24px;
													background-color: ${theme.colors.primary
														.primaryBrand};
													margin-right: 10px;
												`}
											>
												<Icon
													icon="chevron-right"
													color={theme.colors.primary.white}
													size="xs"
												/>
											</div>
											<Typography
												variant="div"
												type="secondary"
												size="sm"
												weight="bold"
												color={theme.colors.primary.header}
												css={css`
													display: inline-block;
													vertical-align: middle;
												`}
											>
												{staticContent[language].viewEpisode}
											</Typography>
										</Link>
									</FlexItem>
								);
							})}
					</Flex>
					{slicePoint < podcastContent[language].length && (
						<LoadMore
							css={css`
								margin-top: 40px;
								border: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setSlicePoint(slicePoint + 3);
							}}
						>
							<Typography
								variant="span"
								color={theme.colors.primary.darkGray}
								weight="bold"
								size="sm"
							>
								{staticContent[language].loadMore}
							</Typography>
						</LoadMore>
					)}
				</ContentSection>
				<StayTunedComponent parent={stayTunedParent} parentCategory={""} />
				<ContactUsForm background="primary" withImage={false} />
				<ContentSection type="primary" vPadding="10px"></ContentSection>
			</Media>
		</>
	);
};

export default Landing;
